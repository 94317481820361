import { ArrowRightAlt } from '@mui/icons-material';
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { EndTurnMessage, CardDrawnEvent, DrawCardMessage, MovePlayerMessage, PlayerMovedEvent } from '../../comms';
import { GameContext, PlayerContext, useSocket, useSocketEventHandler } from '../../context';
import { Levels } from '../../levels';
import { HexTileType } from '../../types';
import { HexGrid } from '../HexGrid';
import { AlienIcon, NoiseAnySectorIcon, NoiseInSectorIcon } from '../Icons';
import { HumanIcon } from '../Icons/HumanIcon';

const HumanDescription = () => (
    <DialogContentText>
        <HumanIcon />
        <Typography variant="h5">You are a human</Typography>
        <Typography variant="h6">Do not reveal this information!</Typography>
        <div>As a human your goal is to get from the starting location to one of the escape pods wthout being killed by the aliens. You move one tile at a time.</div>
    </DialogContentText>
);

const AlienDescription = () => (
    <DialogContentText>
        <AlienIcon />
        <Typography variant="h5">You are an alien</Typography>
        <Typography variant="h6">Do not reveal this information!</Typography>
        <div>As an alien your goal is to hunt and kill the humans before they escape. You move two tiles at a time increasing to three when you devour your first human.</div>
    </DialogContentText>
);

const NoiseInThisSectorCard = () => (
    <DialogContentText>
        <NoiseInSectorIcon />
        <Typography variant="h5">Noise in this sector</Typography>
        <div>Players will be shown noise occurring in the sector you are currently in. Press OK to continue.</div>
    </DialogContentText>
);

const NoiseInAnySectorCard = () => (
    <DialogContentText>
        <NoiseAnySectorIcon />
        <Typography variant="h5">Noise in any sector</Typography>
        <div>After closing this dialog, select which sector noise should be reported as coming from.</div>
    </DialogContentText>
);

export const GameBoard = () => { 

    const { level, currentPlayerIndex, players, allegiance, position, setPosition } = useContext(GameContext);
    const { name: playerName } = useContext(PlayerContext);

    const [ hasSeenAllegiance, setHasSeenAllegiance ] = useState(false);
    const [ hasMoved, setHasMoved ] = useState(false);
    const [ highlightDangerousSectors, setHighlightDangerousSectors ] = useState(false);
    const [ drawnCard, setDrawnCard ] = useState('');

    const socket = useSocket();

    const isPlayersTurn = currentPlayerIndex === players.findIndex(p => p.name === playerName);
    const playerMoveRadius =
        isPlayersTurn && !hasMoved
        ? (allegiance === 'human' ? 1 : 2)
        : 0;

    const handleTileClick = (tile: string) => {
        if(highlightDangerousSectors) {
            socket?.send(new EndTurnMessage('noise', tile));
        } else {
            socket?.send(new MovePlayerMessage(tile));
        }
    };

    useEffect(() => {
        if(!isPlayersTurn) { 
            setHasMoved(false); 
        }
    });

    const onEndTurn = () => {
        if(Levels[level].map[position] === HexTileType.Dangerous) {
            socket?.send(new DrawCardMessage());
        } else {
            socket?.send(new EndTurnMessage('silence'));
        }
    };

    useSocketEventHandler('playerMoved', (event: PlayerMovedEvent) => {
        setPosition(event.position);
        setHasMoved(true);
    });

    useSocketEventHandler('cardDrawn', (event: CardDrawnEvent) => {
        switch(event.card) {
            case 'this':
                setDrawnCard(event.card);
                break;

            case 'any':
                setHighlightDangerousSectors(true);
                setDrawnCard(event.card);
                break;

            case 'silence':
                break;
        }
    });

    const handleCardAccepted = () => {
        if(drawnCard === 'this') {
            socket?.send(new EndTurnMessage('noise', position));
        }
        setDrawnCard('');
    };

    return (
        <React.Fragment>
            <Dialog open={!!allegiance && !hasSeenAllegiance}>
                <DialogTitle>Allegiance</DialogTitle>
                <DialogContent>
                { 
                    allegiance === 'human'
                    ? (<HumanDescription />)
                    : (<AlienDescription />)
                }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setHasSeenAllegiance(true)}>Ok</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={!!drawnCard}>
                <DialogTitle>Dangerous sector</DialogTitle>
                <DialogContent>
                { 
                    drawnCard === 'this'
                    ? (<NoiseInThisSectorCard />)
                    : (<NoiseInAnySectorCard />)
                }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCardAccepted}>Ok</Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item md={12} lg={9}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                {
                                    isPlayersTurn
                                    ? "Your turn"
                                    : `${players[currentPlayerIndex].name}'s turn`
                                }
                            </Typography>
                            <HexGrid 
                                level={Levels[level]} 
                                playerLocation={position} 
                                showMoveRadius={playerMoveRadius} 
                                onTileClick={handleTileClick} 
                                highlightDangerousSectors={highlightDangerousSectors}
                            /> 
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Actions</Typography>
                            <Stack direction={{ md: 'row', lg: 'column' }} sx={{ justifyContent: 'center' }}>
                                <Button disabled>Use item</Button>
                                <Button disabled={!hasMoved || allegiance === 'human'}>Attack</Button>
                                <Button disabled={!hasMoved} onClick={onEndTurn}>End turn</Button>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{ mt: 2 }}>
                        <CardContent>
                            <List>
                            { players.map((player, index) => 
                                <ListItem>
                                    <ListItemIcon>{ currentPlayerIndex === index && (<ArrowRightAlt />) }</ListItemIcon>
                                    <ListItemText>{player.name}</ListItemText>
                                </ListItem>
                            )}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}