export interface IMessage {
    method: string;
};

export class CreateMessage implements IMessage {
    method: string;
    name: string;
    map: string;

    constructor(name: string, map: string) {
        this.method = 'create';
        this.name = name;
        this.map = map;
    }
};

export class DrawCardMessage implements IMessage {
    method: string;

    constructor() {
        this.method = 'draw-card';
    }
};

export class EndTurnMessage implements IMessage {
    method: string;
    announcement: string;
    tile: string;

    constructor(announcement: string, tile?: string) {
        this.method = 'end-turn';
        this.announcement = announcement;
        this.tile = tile || '';
    }
};

export class JoinMessage implements IMessage {
    method: string;
    name: string;
    gameCode: string;

    constructor(name: string, gameCode: string) {
        this.method = 'join';
        this.name = name;
        this.gameCode = gameCode;
    }
};

export class MovePlayerMessage implements IMessage {
    method: string;
    position: string;

    constructor(position: string) {
        this.method = 'move-player';
        this.position = position;
    }
}

export class SetMapMessage implements IMessage {
    method: string;
    map: string;

    constructor(map: string) {
        this.method = 'set-map';
        this.map = map;
    }
};

export class StartGameMessage implements IMessage {
    method: string;

    constructor() {
        this.method = 'start-game';
    }
;}

