import { useState } from 'react';
import styles from './HexGrid.module.css';
import { HexTile } from '..'
import { HexTileType } from '../../types';
import { Level } from '../../levels';

export interface IHexGridProps {
    level: Level,
    playerLocation?: string,
    showMoveRadius?: number,
    allowFinishOnPod?: boolean,
    highlightDangerousSectors?: boolean,
    onTileClick?: (tile: string) => void,
}

const getKey = (row: number, column: number): string => {
    return String.fromCharCode('A'.charCodeAt(0) + column) + (row + 1).toString().padStart(2, '0');
};

const parseKey = (key: string): [row: number, column: number] => {
    const row = parseInt(key.substring(1)) - 1;
    const column = key.charCodeAt(0) - "A".charCodeAt(0);

    return [row, column];
}

export const HexGrid = ({ level, onTileClick, playerLocation, showMoveRadius, allowFinishOnPod, highlightDangerousSectors }: IHexGridProps) => {

    const getAdjacentTiles = (key: string): string[] => {
        const [row, column] = parseKey(key);

        const result = [];
        if (column > 0) { 
            result.push(getKey(row, column - 1)); 
        }
        if (column < 22) {
            result.push(getKey(row, column + 1));
        }

        if(column % 2 === 0) {
            if(row > 0) {
                for(let x = Math.max(0, column - 1); x <= Math.min(23, column + 1); ++x) {
                    result.push(getKey(row - 1, x));
                }
            }
            if(row < 13) {
                result.push(getKey(row + 1, column));
            }
        } else {
            if(row > 0) {
                result.push(getKey(row - 1, column));
            }
            if(row < 13) {
                for(let x = Math.max(0, column - 1); x <= Math.min(23, column + 1); ++x) {
                    result.push(getKey(row + 1, x));
                }
            }
        }
        return result.filter(k => level.map[k] && level.map[k] !== HexTileType.Blank);
    }

    const calculateMoveTiles = () => {

        if(!playerLocation) {
            return [];
        }

        let moveTiles = [playerLocation]

        for(let i = 0; i < (showMoveRadius || 0); ++i) {
            let newTiles: string[] = [];
            for(const tile of moveTiles) {
                newTiles = newTiles.concat(getAdjacentTiles(tile));
            }
            moveTiles = moveTiles.concat(newTiles).filter((tile, index, self) => self.indexOf(tile) === index);
        }

        moveTiles = moveTiles.filter(t => t !== playerLocation && t !== level.alienStart && t !== level.humanStart);

        if(!allowFinishOnPod) {
            moveTiles = moveTiles.filter(t => !level.escapePods.some(p => p === t));
        }

        return moveTiles;
    }
    
    const moveTiles = calculateMoveTiles();

    const handleTileClick = (tile: string) => {
        if(onTileClick && (highlightDangerousSectors || moveTiles.some(t => t === tile))) {
            onTileClick(tile);
        }
    };

    return (
        <div className={styles.grid}>
            {
                Array.from(Array(14).keys()).map(row =>
                    <div key={row} className={styles.row}>{
                        Array.from(Array(23).keys()).map(column => {
                            const key = getKey(row, column);
                            const type = 
                                level.humanStart === key ? HexTileType.HumanStart
                                : level.alienStart === key ? HexTileType.AlienStart
                                : level.escapePods.some(pod => pod === key) ? HexTileType.EscapePod
                                : level.map[key];

                            const text =
                                level.escapePods.some(pod => pod === key) ? level.escapePods.map((pod, i) => ({ pod: pod, index: i + 1})).filter(({pod}) => pod === key)[0].index.toString()
                                : key;

                            return (
                                <HexTile 
                                    type={type}
                                    id={key}
                                    text={text}
                                    onClick={handleTileClick}
                                    highlight={(highlightDangerousSectors && type === HexTileType.Dangerous) || moveTiles.some(t => t === key)}
                                    showPlayerToken={key === playerLocation}
                                    key={key} />
                            );
                        })
                    }</div>
                )
            }
        </div>
    );
}