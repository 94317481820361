import React, { useContext, useState } from 'react';
import { AppBar, Box, Container, Toolbar, Typography } from "@mui/material";
import { useParams, Navigate } from 'react-router-dom';
import { GameContext, useSocket, IGameContext, useSocketEventHandler, DefaultGameContext } from '../../context';
import { Lobby } from '../../components/Lobby';
import { GameBoard } from '../../components/GameBoard';
import { GameStateUpdatedEvent, MapChangedEvent, SetMapMessage, StartGameMessage } from '../../comms';

interface IGameCodeInnerProps {
    gameCode: string;
};

const GameInner = ({ gameCode }: IGameCodeInnerProps) => {
    const [ gameState, setGameState ] = useState<IGameContext>(DefaultGameContext);

    const socket = useSocket();

    useSocketEventHandler('mapChanged', (event: MapChangedEvent) => {
        setGameState(state => ({
            ...state,
            level: event.map,
        }));
    });

    useSocketEventHandler('gameStateUpdated', (event: GameStateUpdatedEvent) => {
        setGameState({
            level: event.state.map,
            hasStarted: event.state.hasStarted,
            allegiance: event.state.allegiance,
            position: event.state.position,
            currentPlayerIndex: event.state.currentPlayerIndex,
            players: event.state.players,
            setPosition: (position: string) => setGameState(state => ({ ...state, position: position })),
        });
    });

    const onLevelSelected = (map: string) => {
        socket?.send(new SetMapMessage(map));
    }

    const onGameStarted = () => {
        socket?.send(new StartGameMessage());
    }

    return (
        <React.Fragment>
            <Box>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div">
                            Escape from the Aliens in Outer Space
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <GameContext.Provider value={gameState}>
                <Container maxWidth="xl">
                    {!gameState.hasStarted && (<Lobby gameCode={gameCode} onLevelSelected={onLevelSelected} onGameStarted={onGameStarted} />)}
                    {gameState.hasStarted && (<GameBoard />)}
                </Container>
            </GameContext.Provider>
        </React.Fragment>
    )
}

export const Game = () => {

    const { gameCode } = useParams();
    const socket = useSocket();

    return (
        socket && gameCode && gameCode.toUpperCase().match(/^[A-Z]{6}$/)
        ? <GameInner gameCode={gameCode} />
        : <Navigate replace to={"/" + (gameCode && `?c=${gameCode}`)} />
    );
};