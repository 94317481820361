const AlienColor = "#c06";

export const AlienIcon = () => (
    <svg width="100%" height="100%" viewBox="0 0 100 60">
        <polyline
            points="30 5, 70 25, 70 45, 50 35, 30 45, 30 25, 70 5"
            fill="none"
            stroke={AlienColor}
            strokeWidth="4"
            />
    </svg>
);