import React, { useContext } from 'react';
import { AppBar, Box, Container, Toolbar, Typography } from "@mui/material";
import { AlienHumanIcon } from '../../components/Icons';
import styles from './Home.module.css';
import { ConnectForm } from '../../components/ConnectForm';
import { useSocket, useSocketEventHandler } from '../../context/SocketContext';
import { useNavigate } from 'react-router-dom';
import { PlayerContext } from '../../context';
import { CreatedEvent, CreateMessage, JoinedEvent, JoinMessage } from '../../comms';
    
export const Home = () => {

    const socket = useSocket();
    const navigate = useNavigate();

    useSocketEventHandler('created', (event: CreatedEvent) => {
        navigate(`/${(event as CreatedEvent).gameCode}`)
    });

    useSocketEventHandler('joined', (event: JoinedEvent) => {
        navigate(`/${(event as JoinedEvent).gameCode}`);
    });

    const { setName: setPlayerName } = useContext(PlayerContext);

    const onGameCreate = (name: string) => {
        socket?.send(new CreateMessage(name, 'Galilei'));
        setPlayerName(name);
    }

    const onGameJoin = (name: string, gameCode: string) => {
        socket?.send(new JoinMessage(name, gameCode));
        setPlayerName(name);
    }

    return (
        <React.Fragment>
            <Box>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div">
                            Escape from the Aliens in Outer Space
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <Container>
                <Box className={styles.logo}>
                    <AlienHumanIcon />
                </Box>
                { socket &&
                    <ConnectForm onCreate={onGameCreate} onJoin={onGameJoin} />
                }
            </Container>
        </React.Fragment>
    )
};