const HumanColor = "#68f";

export const HumanIcon = () => (
    <svg width="100%" height="100%" viewBox="0 0 100 60">
        <polyline
            points="40 5, 30 10, 30 40, 50 45, 70 40, 70 10, 60 5"
            fill="none"
            stroke={HumanColor}
            strokeWidth="4"
            />
        <polyline
            points="30 23, 50 28, 70 23"
            stroke={HumanColor}
            fill="none"
            strokeWidth="4"
            />
    </svg>
);