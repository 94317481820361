import './App.css';
import { PlayerContext, SocketContextProvider } from './context';
import { Game, Home } from './pages';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useState } from 'react';


export const App = () => {
  
  const [playerName, setPlayerName] = useState('');

  return (
    <div className="App">
      <SocketContextProvider>
        <PlayerContext.Provider value={{name: playerName, setName: setPlayerName}}>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path=":gameCode" element={<Game/>} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </PlayerContext.Provider>
      </SocketContextProvider>
    </div>
  );
}
