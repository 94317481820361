import React, { useContext, useState } from 'react';
import { Button, Card, CardContent, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { HexGrid } from '../../components';
import { Levels } from '../../levels';
import { GameContext, useSocketEventHandler, PlayerContext } from '../../context';
import styles from './Lobby.module.css';
import { IPlayer } from '../../types';
import { PlayersChangedEvent } from '../../comms';

interface ILobbyProps {
    gameCode: string;
    onLevelSelected: (map: string) => void;
    onGameStarted: () => void;
}

export const Lobby = ({ gameCode, onLevelSelected, onGameStarted }: ILobbyProps) => {

    const [players, setPlayers] = useState<IPlayer[]>([]);
    const { name: playerName } = useContext(PlayerContext);
    const isLeadPlayer = players.length > 0 && players[0].name === playerName;

    useSocketEventHandler('playersChanged', (event: PlayersChangedEvent) => {
        setPlayers(event.players);
    });

    const { level } = useContext(GameContext);
    
    const onLevelSelectChange = (event: SelectChangeEvent<string>) => {
        onLevelSelected(event.target.value);
    }

    return (
        <React.Fragment>
            <h3>{gameCode}</h3>
            {isLeadPlayer && (<Button disabled={players.length < 2} onClick={() => onGameStarted()}>Start game</Button>)}
            <Grid container spacing={2}>
                <Grid item sm={12} md={8}>
                <Card sx={{ p: 2, pr: 4 }}>
                        <CardContent>
                            <FormControl>
                                <InputLabel id="map-select-label">Map</InputLabel>
                                <Select value={level} disabled={!isLeadPlayer} label="Map" sx={{ mb: 2 }} className={styles.mapSelect} labelId="map-select-label" onChange={onLevelSelectChange}>
                                    {Object.keys(Levels).map(level => <MenuItem value={level}>{level}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <HexGrid level={Levels[level]} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={12} md={4}>
                    <Card sx={{ p: 2, pr: 4 }}>
                        <CardHeader title="Players" />
                        <CardContent>
                            {
                                players.map(player => (
                                        <div>{player.name}</div>))
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}