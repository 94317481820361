const AlienColor = "#c06";
const HumanColor = "#68f";

export const AlienHumanIcon = () => (
    <svg width="100%" height="100%" viewBox="0 0 100 100">
        <polyline
            points="30 5, 70 25, 70 45, 50 35, 30 45, 30 25, 70 5"
            fill="none"
            stroke={AlienColor}
            strokeWidth="4"
            />
        <polyline
            points="40 50, 30 55, 30 85, 50 90, 70 85, 70 55, 60 50"
            fill="none"
            stroke={HumanColor}
            strokeWidth="4"
            />
        <polyline
            points="30 68, 50 73, 70 68"
            stroke={HumanColor}
            fill="none"
            strokeWidth="4"
            />
    </svg>
);