import { EmptyTile, SafeTile, DangerousTile, EscapePodTile, AlienStartTile, HumanStartTile } from './Tiles';
import { HexTileType } from '../../types';
import styles from './HexTile.module.css';

export interface IHexTileProps {
    text: string;
    id: string;
    type: HexTileType;
    highlight?: boolean;
    showPlayerToken?: boolean;
    onClick?: (key: string) => void;
    onMouseMove?: (key: string) => void;
};

export const HexTile = ({ text, id, type, highlight, showPlayerToken, onClick, onMouseMove, ...props }: IHexTileProps) => {
    
    const handleClick = () => onClick && onClick(id);
    const handleMouseMove = () => onMouseMove && onMouseMove(id);
    
    return (
        <div className={highlight ? `${styles.tile} ${styles.selected}` : styles.tile}>
            {
                type === HexTileType.Safe ? <SafeTile text={text} highlight={highlight} showPlayerToken={showPlayerToken} onClick={handleClick} onMouseMove={handleMouseMove} />
                : type === HexTileType.Dangerous ? <DangerousTile text={text} highlight={highlight} showPlayerToken={showPlayerToken} onClick={handleClick} onMouseMove={handleMouseMove} />
                : type === HexTileType.EscapePod ? <EscapePodTile text={text} highlight={highlight} showPlayerToken={showPlayerToken} onClick={handleClick} onMouseMove={handleMouseMove} />
                : type === HexTileType.AlienStart ? <AlienStartTile highlight={highlight} showPlayerToken={showPlayerToken} onClick={handleClick} onMouseMove={handleMouseMove} />
                : type === HexTileType.HumanStart ? <HumanStartTile highlight={highlight} showPlayerToken={showPlayerToken} onClick={handleClick} onMouseMove={handleMouseMove} />
                : <EmptyTile highlight={highlight} onClick={handleClick} onMouseMove={handleMouseMove} />
            }
        </div>
    )
};