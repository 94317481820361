import { createContext } from 'react';
import { Levels } from '../levels';
import { IPlayer } from '../types';

export interface IGameContext {
    level: string;
    hasStarted: boolean;
    currentPlayerIndex: number;
    allegiance: string;
    position: string;
    players: IPlayer[];
    setPosition: (position: string) => void;
}

export const DefaultGameContext = {
    level: Object.keys(Levels)[0], 
    hasStarted: false, 
    currentPlayerIndex: 0, 
    allegiance: '',
    position: '',
    players: [],
    setPosition: () => {},
}

export const GameContext = createContext<IGameContext>(DefaultGameContext);