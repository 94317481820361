const FillColor = "#0a3";
const ArrowColor = '#000';

const hexAngles = 
    Array.from(Array(6).keys())
    .map(a => Math.PI / 6 + (Math.PI / 3) * a);

const hexPoints = (radius: number) => 
    hexAngles
    .map(pointForAngle(radius));

const pointForAngle = (radius: number) => (angle: number) => ({ 
    x: Math.sin(angle) * radius + 50,
    y: Math.cos(angle) * radius + 50 
});

interface IArrowProps {
    angle: number
}

const Arrow = ({ angle }: IArrowProps) => (
    <polygon
        transform={`rotate(${(angle / Math.PI) * 180} 50 50)`}
        points="47 35, 47 20, 44 20, 50 15, 56 20, 53 20, 53 35"
        fill={ArrowColor}
    />
);

export const NoiseAnySectorIcon = () => (
    <svg width="100%" height="100%" viewBox="0 0 100 100">
        <polygon
            points={ hexPoints(40).map(({x, y}) => `${x} ${y}`).join(', ')}
            fill={FillColor}
            strokeWidth={10}
            stroke={FillColor}
            strokeLinejoin='round'
        />
        { hexAngles.map(a => (<Arrow angle={a - Math.PI / 2} />)) }
    </svg>
);