import React from "react";

export interface ITileProps {
    highlight?: boolean;
    showPlayerToken?: boolean;
}

export interface IEventTileProps extends ITileProps {
    onClick: () => void;
    onMouseMove: () => void;
}

export interface ITextTileProps extends IEventTileProps {
    text: string;
}

const borderColor = "#444";
const highlightBorderColor = "#00f";
const playerColor = "#080";

const PlayerToken = () => (
    <React.Fragment>
        <ellipse cx="50" cy="25" rx="10" ry="10" fill={playerColor} />
        <ellipse cx="50" cy="55" rx="15" ry="15" fill={playerColor} />
        <rect x="35" y="55" width="30" height="20" fill={playerColor} />
    </React.Fragment>
);

export const SafeTile = ({ text, highlight, showPlayerToken, onClick, onMouseMove }: ITextTileProps) => (
    <svg width="100%" height="100%" viewBox="0 0 100 100" onClick={onClick} onMouseMove={onMouseMove} preserveAspectRatio="none">
        <polygon 
            points="25 0, 75 0, 100 50, 75 100, 25 100, 0 50" 
            stroke={highlight ? highlightBorderColor : borderColor}
            strokeWidth="3"
            fill="#fff"
        />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="2em" style={{ fill: highlight ? "#99f" : "#bbb" }}>{text}</text>
        {showPlayerToken && <PlayerToken />}
    </svg>
);

export const DangerousTile = ({ text, highlight, showPlayerToken, onClick, onMouseMove }: ITextTileProps) => (
    <svg width="100%" height="100%" viewBox="0 0 100 100" onClick={onClick} onMouseMove={onMouseMove} preserveAspectRatio="none">
        <polygon 
            points="25 0, 75 0, 100 50, 75 100, 25 100, 0 50" 
            stroke={highlight ? highlightBorderColor : borderColor}
            strokeWidth="3"
            fill="#ccc"
        />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="2em" style={{ fill: highlight ? "#66f" : "#888" }}>{text}</text>
        {showPlayerToken && <PlayerToken />}
    </svg>
);

export const EscapePodTile = ({ text, highlight, showPlayerToken, onClick, onMouseMove }: ITextTileProps) => (
    <svg width="100%" height="100%" viewBox="0 0 100 100" onClick={onClick} onMouseMove={onMouseMove} preserveAspectRatio="none">
        <polygon 
            points="25 0, 75 0, 100 50, 75 100, 25 100, 0 50" 
            stroke={highlight ? highlightBorderColor : borderColor}
            strokeWidth="1"
            fill="#000"
            />
        <polyline
            points="12 45, 30 10, 65 10"
            stroke="#fff"
            strokeWidth="8"
            />
        <polyline
            points="35 90, 70 90, 88 55"
            stroke="#fff"
            strokeWidth="8"
            />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="3em" style={{ fill: highlight ? "#99f" : "#fff" }}>{text}</text>
        {showPlayerToken && <PlayerToken />}
    </svg>
);

export const AlienStartTile = ({ onClick, showPlayerToken, onMouseMove }: IEventTileProps) => (
    <svg width="100%" height="100%" viewBox="0 0 100 100" onClick={onClick} onMouseMove={onMouseMove} preserveAspectRatio="none">
        <polygon 
            points="25 0, 75 0, 100 50, 75 100, 25 100, 0 50" 
            stroke="#444"
            strokeWidth="1"
            fill="#000"
            />
        <polyline
            points="30 10, 70 50, 70 90, 50 70, 30 90, 30 50, 70 10"
            stroke="#fff"
            strokeWidth="8"
            />
        {showPlayerToken && <PlayerToken />}
    </svg>
);

export const HumanStartTile = ({ onClick, showPlayerToken, onMouseMove }: IEventTileProps) => (
    <svg width="100%" height="100%" viewBox="0 0 100 100" onClick={onClick} onMouseMove={onMouseMove} preserveAspectRatio="none">
        <polygon 
            points="25 0, 75 0, 100 50, 75 100, 25 100, 0 50" 
            stroke="#444"
            strokeWidth="1"
            fill="#000"
            />
        <polyline
            points="40 10, 30 20, 30 80, 50 90, 70 80, 70 20, 60 10"
            stroke="#fff"
            strokeWidth="8"
            />
        <polyline
            points="30 45, 50 55, 70 45"
            stroke="#fff"
            strokeWidth="8"
            />
        {showPlayerToken && <PlayerToken />}
    </svg>
);

export const EmptyTile = ({ onClick, onMouseMove }: IEventTileProps) => (
    <svg width="100%" height="100%" viewBox="0 0 100 100" onClick={onClick} onMouseMove={onMouseMove} preserveAspectRatio="none">
        <polygon 
            points="25 0, 75 0, 100 50, 75 100, 25 100, 0 50" 
            stroke="black"
            strokeWidth="0"
            fill="none"
            />
    </svg>
);
