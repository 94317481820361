const FillColor = "#f00";
const ArrowColor = '#000';

const hexPoints = (radius: number) => 
    Array.from(Array(6).keys())
    .map(a => Math.PI / 6 + (Math.PI / 3) * a)
    .map(pointForAngle(radius));

const pointForAngle = (radius: number) => (angle: number) => ({ 
    x: Math.sin(angle) * radius + 50,
    y: Math.cos(angle) * radius + 50 
});

export const NoiseInSectorIcon = () => (
    <svg width="100%" height="100%" viewBox="0 0 100 100">
        <polygon
            points={ hexPoints(40).map(({x, y}) => `${x} ${y}`).join(', ')}
            fill={FillColor}
            strokeWidth={10}
            stroke={FillColor}
            strokeLinejoin='round'
        />
        <polygon
            points="40 60, 20 65, 35 80, 65 80, 80 65, 60 60"
            stroke={ArrowColor}
            strokeWidth={5}
            strokeLinejoin="round"
            fill="none"
        />
        <polygon
            points="38 25, 41 50, 23 50, 50 73, 77 50, 59 50, 62 25"
            fill={ArrowColor}
            stroke={FillColor}
            strokeWidth={2}
        />
    </svg>
);