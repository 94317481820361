import React, { useState } from 'react';
import { Alert, Box, Button, Card, TextField } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useSocketEventHandler } from '../../context';
import { ErrorEvent } from '../../comms';

interface IConnectFormProps {
    onCreate: (name: string) => void;
    onJoin: (name: string, gameCode: string) => void;
}

const cleanGameCode = (gameCode: string): string => 
    gameCode.substring(0, 6).toUpperCase().replaceAll(/[^A-Z]/g, '');

export const ConnectForm = ({ onCreate, onJoin}: IConnectFormProps) => {

    const [searchParams] = useSearchParams();
    
    const [ gameCode, setGameCode ] = useState(cleanGameCode(searchParams.get('c') || ''));
    const [ name, setName ] = useState('');
    const [ error, setError ] = useState('');

    useSocketEventHandler('error', (event: ErrorEvent) => {
        setError(event.message);
    })

    const onNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value.replaceAll(/[^A-Za-z0-9!?*()\-]/g, '').substring(0, 20));
    };

    const onGameCodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGameCode(cleanGameCode(event.target.value));
    };

    return (
        <Box component="form">
            {error && (<Alert severity='error'>{error}</Alert>)}
            <Card sx={{ p: 2, pr: 4 }}>
                <TextField sx={{ m: 1 }} fullWidth variant='outlined' label="Name" value={name} onChange={onNameChanged}></TextField>
                <TextField sx={{ m: 1 }} fullWidth variant="outlined" label="Code" value={gameCode} onChange={onGameCodeChanged}></TextField>
                {
                    gameCode === '' ? (<Button variant="contained" disabled={!name} onClick={() => onCreate(name)}>Create Game</Button>)
                    : (<Button variant="contained" disabled={!name || gameCode.length < 6} onClick={() => onJoin(name, gameCode)}>Join Game</Button>)
                }
            </Card>
        </Box>
    );
};