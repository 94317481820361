import React from 'react';

export interface IPlayerContext {
    name: string;
    setName: (name: string) => void;
}

export const PlayerContext = React.createContext<IPlayerContext>({ 
    name: '',
    setName: () => {},
});